import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import config, { modules } from "../../../../config/config";
import { CSVReader } from "react-papaparse";

export function ImportCsvOfficina(props) {
  const { user } = useSelector((state) => state.auth);

  const buttonRef = React.createRef();

  const [dati, setDati] = useState([]);
  const [colonne, setColonne] = useState([]);
  const [load, setLoading] = useState(false);
  const [page, setPage] = useState("import");
  const [result, setResult] = useState([]);
  const [lastSettings, setLastSettings] = useState({
    exId: 0,
    oreTotali: 0,
    dataLavoro: 0,
    numCommessa: 0,
    addebito: 0,
    desLavoro: 0,
    unLavoro: 0,
    desSede: 0,
  });

  const handleOnFileLoad = (data) => {
    setColonne(data[0].data);
    setDati(data.slice(1).map((e) => e.data));
  };

  const handleOnError = (err, file, inputElem, reason) => {
    console.log("---------------------------");
    console.log(err);
    console.log("---------------------------");
  };

  const handleOnRemoveFile = (data) => {
    console.log("---------------------------");
    console.log(data);
    console.log("---------------------------");
  };

  const handleRemoveFile = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.removeFile(e);
    }
  };

  const handleOpenDialog = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  const schema = Yup.object().shape({
    exId: Yup.number().required("Selezionare il campo corrispondente"),
    oreTotali: Yup.number().required("Selezionare il campo corrispondente"),
    dataLavoro: Yup.number().required("Selezionare il campo corrispondente"),
    numCommessa: Yup.number().required("Selezionare il campo corrispondente"),
    addebito: Yup.number().required("Selezionare il campo corrispondente"),
    unLavoro: Yup.number().required("Selezionare il campo corrispondente"),
    desLavoro: Yup.number().required("Selezionare il campo corrispondente"),
    desSede: Yup.number().required("Selezionare il campo corrispondente"),
  });

  const formik = useFormik({
    initialValues: lastSettings,
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: (values) => {
      setLoading(true);
      const requestOptions = {
        headers: {
          Authorization:
            "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
        },
        method: "POST",
        body: JSON.stringify({
          colonne: values,
          dati: dati,
          idUser: user.id,
          idOrganization:
            user.id_organization === 0 ? user.id : user.id_organization,
        }),
      };

      fetch(config.apiUrl + "obiettivi/ImportCsvOfficina.php", requestOptions)
        .then((response) => response.json())
        .then((data) => setResult(data))
        .then(() => setLoading(false))
        .then(() => setPage("result"));
    },
  });

  const GetLastSettings = () => {
    const requestOptions = {
      headers: {
        Authorization:
          "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
      },
      method: "POST",
      body: JSON.stringify({
        idUser: user.id,
        idOrganization:
          user.id_organization === 0 ? user.id : user.id_organization,
        idModule: modules.officina,
      }),
    };
    fetch(config.apiUrl + "obiettivi/GetLastSettings.php", requestOptions)
      .then((response) => response.json())
      .then((data) => setLastSettings(data));
  };

  useEffect(() => {
    GetLastSettings();
    /*eslint-disable-next-line*/
  }, []);

  return (
    <Modal backdrop="static" show={props.show} onHide={props.close}>
      <Modal.Header closeButton>
        <Modal.Title>
          {page === "import" ? "Importa file CSV (Officina)" : null}
          {page === "result"
            ? result["code"] === 200
              ? "Importazione riuscita"
              : "Importazione fallita"
            : null}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {page === "import" ? (
          <>
            <CSVReader
              ref={buttonRef}
              onFileLoad={handleOnFileLoad}
              onError={handleOnError}
              noClick
              noDrag
              onRemoveFile={handleOnRemoveFile}
            >
              {({ file }) => (
                <aside
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: 10,
                  }}
                >
                  <Button
                    style={{
                      float: "left",
                    }}
                    size="sm"
                    variant="success"
                    onClick={handleOpenDialog}
                  >
                    Seleziona file
                  </Button>
                  <div
                    style={{
                      borderWidth: 1,
                      borderStyle: "solid",
                      borderColor: "#ccc",
                      height: 35,
                      lineHeight: 2.5,
                      paddingLeft: 13,
                      paddingTop: 3,
                      width: "60%",
                    }}
                  >
                    {file && file.name}
                  </div>
                  <Button
                    size="sm"
                    style={{
                      float: "right",
                    }}
                    variant="danger"
                    onClick={handleRemoveFile}
                  >
                    Remove
                  </Button>
                </aside>
              )}
            </CSVReader>
            <Form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-6">
                  <label className="col-xl-12 col-lg-12 col-form-label">
                    ID utente
                  </label>
                  <div className="col-lg-12 col-xl-12">
                    <select
                      onChange={formik.handleChange}
                      value={formik.values.exId}
                      className="form-control"
                      name="exId"
                    >
                      <option>Seleziona colonna corrispondente</option>
                      {colonne.map((e, key) => {
                        return (
                          <option key={key} value={key}>
                            {e}
                          </option>
                        );
                      })}
                    </select>

                    {formik.touched.exId && formik.errors.exId ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.exId}
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <label className="col-xl-12 col-lg-12 col-form-label">
                    Ore attività
                  </label>
                  <div className="col-lg-12 col-xl-12">
                    <select
                      onChange={formik.handleChange}
                      value={formik.values.oreTotali}
                      className="form-control"
                      name="oreTotali"
                    >
                      <option>Seleziona colonna corrispondente</option>
                      {colonne.map((e, key) => {
                        return (
                          <option key={key} value={key}>
                            {e}
                          </option>
                        );
                      })}
                    </select>

                    {formik.touched.oreTotali && formik.errors.oreTotali ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.oreTotali}
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <label className="col-xl-12 col-lg-12 col-form-label">
                    Data lavoro
                  </label>
                  <div className="col-lg-12 col-xl-12">
                    <select
                      onChange={formik.handleChange}
                      value={formik.values.dataLavoro}
                      className="form-control"
                      name="dataLavoro"
                    >
                      <option>Seleziona colonna corrispondente</option>
                      {colonne.map((e, key) => {
                        return (
                          <option key={key} value={key}>
                            {e}
                          </option>
                        );
                      })}
                    </select>

                    {formik.touched.dataLavoro && formik.errors.dataLavoro ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.dataLavoro}
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <label className="col-xl-12 col-lg-12 col-form-label">
                    Addebito
                  </label>
                  <div className="col-lg-12 col-xl-12">
                    <select
                      onChange={formik.handleChange}
                      value={formik.values.addebito}
                      className="form-control"
                      name="addebito"
                    >
                      <option>Seleziona colonna corrispondente</option>
                      {colonne.map((e, key) => {
                        return (
                          <option key={key} value={key}>
                            {e}
                          </option>
                        );
                      })}
                    </select>

                    {formik.touched.addebito && formik.errors.addebito ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.addebito}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-6">
                  <label className="col-xl-12 col-lg-12 col-form-label">
                    Descrizione lavoro
                  </label>
                  <div className="col-lg-12 col-xl-12">
                    <select
                      onChange={formik.handleChange}
                      value={formik.values.desLavoro}
                      className="form-control"
                      name="desLavoro"
                    >
                      <option>Seleziona colonna corrispondente</option>
                      {colonne.map((e, key) => {
                        return (
                          <option key={key} value={key}>
                            {e}
                          </option>
                        );
                      })}
                    </select>

                    {formik.touched.desLavoro && formik.errors.desLavoro ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.desLavoro}
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <label className="col-xl-12 col-lg-12 col-form-label">
                    Unità lavoro
                  </label>
                  <div className="col-lg-12 col-xl-12">
                    <select
                      onChange={formik.handleChange}
                      value={formik.values.unLavoro}
                      className="form-control"
                      name="unLavoro"
                    >
                      <option>Seleziona colonna corrispondente</option>
                      {colonne.map((e, key) => {
                        return (
                          <option key={key} value={key}>
                            {e}
                          </option>
                        );
                      })}
                    </select>

                    {formik.touched.unLavoro && formik.errors.unLavoro ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.unLavoro}
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <label className="col-xl-12 col-lg-12 col-form-label">
                    Numero commessa
                  </label>
                  <div className="col-lg-12 col-xl-12">
                    <select
                      onChange={formik.handleChange}
                      value={formik.values.numCommessa}
                      className="form-control"
                      name="numCommessa"
                    >
                      <option>Seleziona colonna corrispondente</option>
                      {colonne.map((e, key) => {
                        return (
                          <option key={key} value={key}>
                            {e}
                          </option>
                        );
                      })}
                    </select>

                    {formik.touched.numCommessa && formik.errors.numCommessa ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.numCommessa}
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <label className="col-xl-12 col-lg-12 col-form-label">
                    Descrizione sede
                  </label>
                  <div className="col-lg-12 col-xl-12">
                    <select
                      onChange={formik.handleChange}
                      value={formik.values.desSede}
                      className="form-control"
                      name="desSede"
                    >
                      <option>Seleziona colonna corrispondente</option>
                      {colonne.map((e, key) => {
                        return (
                          <option key={key} value={key}>
                            {e}
                          </option>
                        );
                      })}
                    </select>
                    {formik.touched.desSede && formik.errors.desSede ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.desSede}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </Form>
          </>
        ) : (
          result["message"]
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.close}>
          {page === "import" ? "Annulla" : "Chiudi"}
        </Button>
        {page === "import" ? (
          <Button
            disabled={load}
            onClick={formik.handleSubmit}
            variant="primary"
          >
            Conferma
          </Button>
        ) : null}
        {page === "result" ? (
          result["code"] === 200 ? (
            <Button
              disabled={load}
              variant="primary"
              onClick={() => {
                setPage("import");
                setColonne([]);
                setDati([]);
              }}
            >
              Nuova importazione
            </Button>
          ) : (
            <Button
              disabled={load}
              variant="primary"
              onClick={() => {
                setPage("import");
                setColonne([]);
                setDati([]);
              }}
            >
              Riprova
            </Button>
          )
        ) : null}
      </Modal.Footer>
    </Modal>
  );
}
