/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { roles } from "../../../../../config/config";
import { useSelector } from "react-redux";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

export function AsideMenuList({ layoutProps }) {
  /*eslint-disable */
  const { user } = useSelector((state) => state.auth);
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${
          !hasSubmenu && "menu-item-active"
        } menu-item-open menu-item-not-hightlighted`
      : "";
  };
  var path =
    parseInt(user.id_role) === roles.admin
      ? "/obiettivi/" +
        user.moduleName.replace(/\s+/g, "-").trim().toLowerCase()
      : "/obiettivi/" +
        user.nameDepartment.replace(/\s+/g, "-").trim().toLowerCase();

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Shopping/Chart-line1.svg")}
              />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/* begin::section */}
        {user[0].presenze === true || parseInt(user.idModule) !== 0 ? (
          <li className="menu-section ">
            <h4 className="menu-text">Obiettivi lavoro</h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li>
        ) : null}
        {/* end:: section */}

        {/*begin::1 Level

        {parseInt(user.idModule) !== 0 ? (
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/obiettivi",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/obiettivi">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Thumbtack.svg"
                  )}
                />
              </span>
              <span className="menu-text">Obiettivi</span>
            </NavLink>
            <div className="menu-submenu">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li className="menu-item menu-item-parent" aria-haspopup="true">
                  <span className="menu-link">
                    <span className="menu-text">Obiettivi</span>
                  </span>
                </li>
                {/*begin::2 Level*/}
        {/*<li
                  className={`menu-item ${getMenuItemActive(
                    "/e-commerce/customers"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to={path}>
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">
                      {parseInt(user.id_role) === roles.admin
                        ? user.nameDepartment
                        : user.moduleName}
                    </span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}
        {/*</ul>
            </div>
          </li>
        ) : null}
        end::1 Level*/}

        {/*begin::1 Level*/}
        {parseInt(user.idModule) !== 0 ? (
          <li
            className={`menu-item ${getMenuItemActive("/obiettivi", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/obiettivi">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Thumbtack.svg"
                  )}
                />
              </span>
              <span className="menu-text">Obiettivi</span>
            </NavLink>
          </li>
        ) : null}
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {user[0].presenze === true ? (
          <li
            className={`menu-item ${getMenuItemActive("/presenze", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/presenze">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Edit.svg")} />
              </span>
              <span className="menu-text">Presenze</span>
            </NavLink>
          </li>
        ) : null}

        {/* {user[0].documenti === true || user[0].bustePaga === true ? (
          <li className="menu-section ">
            <h4 className="menu-text">File e risorse</h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li>
        ) : null}
        
        {user[0].bustePaga === true ? (
          <li
            className={`menu-item ${getMenuItemActive("/buste-paga", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/buste-paga">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Shopping/Wallet.svg")}
                />
              </span>
              <span className="menu-text">Buste paga</span>
            </NavLink>
          </li>
        ) : null}
        
        {user[0].documenti === true ? (
          <li
            className={`menu-item ${getMenuItemActive("/documenti", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/documenti">
              <span className="fas fa-folder svg-icon menu-icon"></span>
              <span className="menu-text">Documenti</span>
            </NavLink>
          </li>
        ) : null} */}

        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">Dipendenti</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}

        {/*begin::1 Level*/}
        {user[0].dipendenti === true ? (
          <li
            className={`menu-item ${getMenuItemActive("/dipendenti", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/dipendenti">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
              </span>
              <span className="menu-text">Dipendenti</span>
            </NavLink>
          </li>
        ) : null}
        {/*end::1 Level*/}

        {/*begin::1 Level*/}

        {parseInt(user.id_role) === roles.admin ? (
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/valutazioni",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/valutazioni">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Design/Pen-tool-vector.svg"
                  )}
                />
              </span>
              <span className="menu-text">Valutazione</span>
            </NavLink>
            <div className="menu-submenu">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li className="menu-item menu-item-parent" aria-haspopup="true">
                  <span className="menu-link">
                    <span className="menu-text">Valutazione</span>
                  </span>
                </li>

                {/*begin::3 Level*/}

                <li
                  className={`menu-item ${getMenuItemActive("/valutazioni")}`}
                  aria-haspopup="true"
                >
                  <NavLink
                    className="menu-link"
                    to="/valutazioni/valuta-dipendenti"
                  >
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Valutazioni</span>
                  </NavLink>
                </li>
                {/*end::3 Level*/}

                {/*begin::5 Level*/}
                {parseInt(user.id_role) === roles.admin ? (
                  <li
                    className={`menu-item ${getMenuItemActive("/valutazioni")}`}
                    aria-haspopup="true"
                  >
                    <NavLink
                      className="menu-link"
                      to="/valutazioni/impostazioni-valutazione"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        Impostazioni valutazioni
                      </span>
                    </NavLink>
                  </li>
                ) : null}
                {/*end::5 Level*/}
              </ul>
            </div>
          </li>
        ) : (
          <li
            className={`menu-item ${getMenuItemActive(
              "/valutazioni/valuta-dipendenti",
              false
            )}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/valutazioni/valuta-dipendenti">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Design/Pen-tool-vector.svg"
                  )}
                />
              </span>
              <span className="menu-text">Valutazioni</span>
            </NavLink>
          </li>
        )}
        {/*end::1 Level*/}

        {/* begin::section */}
        {user[0].pannelloAdmin === true ? (
          <li className="menu-section ">
            <h4 className="menu-text">Impostazioni</h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li>
        ) : null}
        {/* end:: section */}

        {/*begin::1 Level*/}
        {user[0].pannelloAdmin === true ? (
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/pannello-admin",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/pannello-admin">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Code/Settings4.svg")}
                />
              </span>
              <span className="menu-text">Pannello Admin</span>
            </NavLink>
            <div className="menu-submenu">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li className="menu-item menu-item-parent" aria-haspopup="true">
                  <span className="menu-link">
                    <span className="menu-text">Pannello Admin</span>
                  </span>
                </li>
                {/*begin::2 Level*/}
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/pannello-admin"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink
                    className="menu-link"
                    to="/pannello-admin/gestisci-ruoli"
                  >
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Gestisci ruoli</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}

                {/*begin::3 Level*/}
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/pannello-admin"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink
                    className="menu-link"
                    to="/pannello-admin/gestisci-gruppi"
                  >
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Dipartimenti</span>
                  </NavLink>
                </li>
                {/*end::3 Level*/}

                {/*begin::4 Level*/}
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/pannello-admin"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink
                    className="menu-link"
                    to="/pannello-admin/impostazioni"
                  >
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Impostazioni</span>
                  </NavLink>
                </li>
                {/*end::4 Level*/}
              </ul>
            </div>
          </li>
        ) : null}
        {/*end::1 Level*/}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
