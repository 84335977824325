import React, { useEffect, useState } from "react";
import config, { roles } from "../../../../../config/config";
import { Button } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import { Chip } from "@material-ui/core";
import {
  Grid,
  Table,
  PagingPanel,
  TableHeaderRow,
  TableGroupRow,
  Toolbar,
  ColumnChooser,
  TableColumnVisibility,
} from "@devexpress/dx-react-grid-bootstrap4";
import { DataTypeProvider } from "@devexpress/dx-react-grid";
import {
  PagingState,
  IntegratedPaging,
  GroupingState,
  IntegratedGrouping,
} from "@devexpress/dx-react-grid";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import { ModalImportVenditori } from "../../mixed/ModalImportVenditori";

export const TabellaVenditori = (props) => {
  const { user } = useSelector((state) => state.auth);

  const [importModal, setImportModal] = useState(false);

  const handleOpenImport = () => setImportModal(true);
  const handleCloseImport = () => setImportModal(false);

  const [pageSizes] = useState([10, 25, 50, 100, 0]);
  const [contratto] = useState(["stato"]);

  const columns = [
    { name: "", title: "" },
    { name: "idTrattativa", title: "Trattativa" },
    { name: "idVenditore", title: "ID venditore" },
    { name: "venditore", title: "Venditore" },
    { name: "codiceOfferta", title: "Codice offerta" },
    { name: "stato", title: "Stato trattiva" },
  ];

  const [rows, setRows] = useState([]);
  const [riuscite, setRiuscite] = useState("0/0");

  const GetContratti = () => {
    const requestOptions = {
      headers: {
        Authorization:
          "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
      },
      method: "POST",
      body: JSON.stringify({
        idUser: props.idUser,
        idRuolo: props.idRuolo,
      }),
    };
    fetch(config.apiUrl + "obiettivi/GetAllContratti.php", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setRows(data.tabella);
        setRiuscite(data.riuscite);
      });
  };

  useEffect(() => {
    if (props.idUser) {
      GetContratti();
    }
    /*eslint-disable-next-line*/
  }, [props.idUser]);

  const PresenzaFormatter = ({ value }) => {
    var color;
    switch (value) {
      case "PREVENTIVO":
        color = "#f9c74f";
        break;
      case "CONTRATTO":
        color = "#90be6d";
        break;
      case "MANCATA VENDITA":
        color = "#d00000";
        break;
      default:
        color = "#8a817c";
    }
    return (
      <>
        <Chip
          size="large"
          label={value}
          icon={
            <i style={{ color: color }} className="fas fa-circle fa-sm"></i>
          }
        />
      </>
    );
  };

  const CurrencyTypeProvider = (props) => (
    <DataTypeProvider formatterComponent={PresenzaFormatter} {...props} />
  );

  const TableComponent = ({ ...restProps }) => (
    <Table.Table {...restProps} className="table-striped" />
  );

  return (
    <div className="row">
      <ModalImportVenditori
        handleCloseImport={handleCloseImport}
        importModal={importModal}
      />
      {user.importCsv === true &&
      (parseInt(user.id_role) === roles.hr ||
        parseInt(user.id_role) === roles.admin) ? (
        <>
          <span className="col-12">
            <Button
              onClick={() => handleOpenImport()}
              variant="primary"
              className="p-4 float-left"
              size="sm"
            >
              Importa CSV
            </Button>

            <CSVLink
              data={rows}
              filename={"trattative.csv"}
              className="btn btn-primary btn-sm font-weight-bold p-4 float-right"
              size={"small"}
              separator={";"}
            >
              Esporta CSV{" "}
            </CSVLink>
          </span>
        </>
      ) : null}
      <div className="col-12">
        <Grid rows={rows} columns={columns}>
          <CurrencyTypeProvider for={contratto} />
          <GroupingState grouping={[{ columnName: "idTrattativa" }]} />
          <IntegratedGrouping />
          <PagingState defaultCurrentPage={0} defaultPageSize={10} />
          <IntegratedPaging />
          <Table tableComponent={TableComponent} />
          <TableHeaderRow />
          <TableGroupRow />
          <PagingPanel pageSizes={pageSizes} />
          <TableColumnVisibility />
          <Toolbar />
          <ColumnChooser />
        </Grid>
      </div>
    </div>
  );
};
