import React from "react";
import { useSelector } from "react-redux";
import { CustomerCare } from "./obiettivi/CustomerCare";
import { Venditori } from "./obiettivi/Venditori";
import { Officina } from "./obiettivi/Officina";
import { Admin } from "./obiettivi/Admin";
import { modules, roles } from "../../config/config";

export const Obiettivi = () => {
  const user = useSelector((state) => state.auth.user);

  if (parseInt(user.id_role) === roles.admin) {
    return (
      <Admin
        idOrganization={
          parseInt(user.id_organization) === 0 ? user.id : user.id_organization
        }
        authToken={user.authToken}
        idRole={user.id_role}
      />
    );
  } else if (
    parseInt(user.id_role) !== roles.admin &&
    parseInt(user.idModule) === modules.customerCare
  ) {
    return (
      <CustomerCare
        idOrganization={
          parseInt(user.id_organization) === 0 ? user.id : user.id_organization
        }
        authToken={user.authToken}
        idRole={user.id_role}
      />
    );
  } else if (
    parseInt(user.id_role) !== roles.admin &&
    parseInt(user.idModule) === modules.officina
  ) {
    return (
      <Officina
        idOrganization={
          parseInt(user.id_organization) === 0 ? user.id : user.id_organization
        }
        authToken={user.authToken}
        idRole={user.id_role}
      />
    );
  } else if (
    parseInt(user.id_role) !== roles.admin &&
    parseInt(user.idModule) === modules.venditori
  ) {
    return (
      <Venditori
        idOrganization={
          parseInt(user.id_organization) === 0 ? user.id : user.id_organization
        }
        authToken={user.authToken}
        idRole={user.id_role}
      />
    );
  }
};