/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import config from "../../../../config/config";
import Rating from "@material-ui/lab/Rating";
import Box from "@material-ui/core/Box";

export function AdvanceTablesWidget4({ className }) {
  /*eslint-disable */
  const { user } = useSelector((state) => state.auth);

  const [valutazioni, setValutazioni] = useState([]);
  const [loading, setLoading] = useState(false);

  const requestOptions = {
    headers: {
      Authorization:
        "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
    },
    method: "POST",
    body: JSON.stringify({
      idUser: user.id,
      idRole: user.id_role,
      idDepartment: user.idDepartment,
      idOrganization: user.id_organization,
    }),
  };

  const GetValutazioniDashboard = () => {
    setLoading(true);
    fetch(config.apiUrl + "users/GetDashboardValutation.php", requestOptions)
      .then((response) => response.json())
      .then((data) => setValutazioni(data))
      .then(() => setLoading(false));
  };

  useEffect(() => {
    GetValutazioniDashboard();
  }, []);

  return (
    <div className={`card card-custom ${className}`}>
      {/* Head */}
      <div className="card-dashboard border-0 py-5">
        <h3
          style={{ color: "black !important" }}
          className="card-title align-items-start flex-column"
        >
          <span
            style={{ color: "black !important" }}
            className="card-label font-weight-bolder"
          >
            ULTIME CINQUE VALUTAZIONI
          </span>
        </h3>
      </div>
      {/* Body */}
      <div className="card-body pt-0 pb-3">
        <div className="tab-content">
          <div className="table-responsive">
            <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
              <thead>
                <tr className="text-left text-uppercase">
                  <th className="pl-7" style={{ minWidth: "100px" }}>
                    <span className="text-dark-75">nome</span>
                  </th>
                  <th style={{ minWidth: "100px" }}>email</th>
                  <th style={{ minWidth: "100px" }}>dipartimento</th>
                  <th style={{ minWidth: "150px" }}>valutazione</th>
                </tr>
              </thead>
              <tbody>
                {valutazioni !== "undefined"
                  ? valutazioni.map((e, key) => (
                      <tr>
                        <td className="pl-0 py-8">
                          <div className="d-flex align-items-center">
                            <div>
                              <a className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
                                {e.nomeDipendente}
                              </a>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                            {e.email}
                          </span>
                        </td>
                        <td>
                          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                            {e.dipartimento}
                          </span>
                        </td>
                        <td>
                          <Box
                            className="mt-8"
                            component="fieldset"
                            mb={3}
                            borderColor="transparent"
                          >
                            <Rating
                              name="read-only"
                              value={e.valutatione}
                              readOnly
                            />
                          </Box>
                        </td>
                      </tr>
                    ))
                  : "NESSUNA VALUTAZIONE"}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
