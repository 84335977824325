import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import config, { modules } from "../../../../config/config";
import { CSVReader } from "react-papaparse";

export function ModalImportCsv(props) {
  const { user } = useSelector((state) => state.auth);

  const buttonRef = React.createRef();

  const [dati, setDati] = useState([]);
  const [colonne, setColonne] = useState([]);
  const [result, setResult] = useState([]);
  const [load, setLoading] = useState(false);
  const [page, setPage] = useState("import");
  const [lastSettings, setLastSettings] = useState({
    exId: "",
    tipoChiamata: "",
    dataRicevuta: "",
    oraRicevuta: "",
    durata: "",
    nrDialed: "",
    ringTime: "",
    numeroChiamante: "",
    nomeSoggetto: "",
  });

  useEffect(() => {
    GetLastSettings();
    /*eslint-disable-next-line*/
  }, []);

  const handleOnFileLoad = (data) => {
    setColonne(data[0].data);
    setDati(data.slice(1).map((e) => e.data));
  };

  const handleOnError = (err, file, inputElem, reason) => {
    console.log("---------------------------");
    console.log(err);
    console.log("---------------------------");
  };

  const handleOnRemoveFile = (data) => {
    console.log("---------------------------");
    console.log(data);
    console.log("---------------------------");
  };

  const handleRemoveFile = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.removeFile(e);
    }
  };

  const handleOpenDialog = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  const GetLastSettings = () => {
    const requestOptions = {
      headers: {
        Authorization:
          "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
      },
      method: "POST",
      body: JSON.stringify({
        idUser: user.id,
        idOrganization:
          user.id_organization === 0 ? user.id : user.id_organization,
        idModule: modules.customerCare,
      }),
    };
    fetch(config.apiUrl + "obiettivi/GetLastSettings.php", requestOptions)
      .then((response) => response.json())
      .then((data) => setLastSettings(data));
  };

  const schema = Yup.object().shape({
    exId: Yup.number().required("Selezionare il campo corrispondente"),
    tipoChiamata: Yup.number().required("Selezionare il campo corrispondente"),
    dataRicevuta: Yup.number().required("Selezionare il campo corrispondente"),
    oraRicevuta: Yup.number().required("Selezionare il campo corrispondente"),
    durata: Yup.number().required("Selezionare il campo corrispondente"),
    nrDialed: Yup.number().required("Selezionare il campo corrispondente"),
    ringTime: Yup.number().required("Selezionare il campo corrispondente"),
    numeroChiamante: Yup.number().required(
      "Selezionare il campo corrispondente"
    ),
    nomeSoggetto: Yup.number().required("Selezionare il campo corrispondente"),
  });

  const formik = useFormik({
    initialValues: lastSettings,
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: (values) => {
      setLoading(true);
      const requestOptions = {
        headers: {
          Authorization:
            "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
        },
        method: "POST",
        body: JSON.stringify({
          colonne: values,
          dati: dati,
          useCustomField: true,
          idOrganization: user.id_organization,
          idUser: user.id,
        }),
      };

      fetch(
        config.apiUrl + "obiettivi/importCsvCustomerCare.php",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => setResult(data))
        .then(() => setLoading(false))
        .then(() => setPage("result"));
    },
  });

  return (
    <Modal
      backdrop="static"
      show={props.importModal}
      onHide={props.handleCloseImport}
    >
      <Modal.Header style={{ backgroundColor: "#caf0f8" }} closeButton>
        <Modal.Title>
          {page === "import" ? "Importa file CSV (Customer care)" : null}
          {page === "result"
            ? result["code"] === 200
              ? "Importazione riuscita"
              : "Importazione fallita"
            : null}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {page === "import" ? (
          <>
            <CSVReader
              ref={buttonRef}
              onFileLoad={handleOnFileLoad}
              onError={handleOnError}
              noClick
              noDrag
              onRemoveFile={handleOnRemoveFile}
            >
              {({ file }) => (
                <aside
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: 10,
                  }}
                >
                  <Button
                    style={{
                      float: "left",
                    }}
                    size="sm"
                    variant="success"
                    onClick={handleOpenDialog}
                  >
                    Seleziona file
                  </Button>
                  <div
                    style={{
                      borderWidth: 1,
                      borderStyle: "solid",
                      borderColor: "#ccc",
                      height: 35,
                      lineHeight: 2.5,
                      paddingLeft: 13,
                      paddingTop: 3,
                      width: "60%",
                    }}
                  >
                    {file && file.name}
                  </div>
                  <Button
                    size="sm"
                    style={{
                      float: "right",
                    }}
                    variant="danger"
                    onClick={handleRemoveFile}
                  >
                    Remove
                  </Button>
                </aside>
              )}
            </CSVReader>
            <Form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-6">
                  <label className="col-xl-12 col-lg-12 col-form-label">
                    Codice Sipadx
                  </label>
                  <div className="col-lg-12 col-xl-12">
                    <div className="input-group input-group-lg input-group-solid">
                      <select
                        onChange={formik.handleChange}
                        value={formik.values.exId}
                        className="form-control form-control-lg form-control-solid"
                        name="exId"
                      >
                        <option>Seleziona colonna corrispondente</option>
                        {colonne.map((e, key) => {
                          return (
                            <option key={key} value={key}>
                              {e}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    {formik.touched.exId && formik.errors.exId ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.exId}
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <label className="col-xl-12 col-lg-12 col-form-label">
                    Tipo Chiamata
                  </label>
                  <div className="col-lg-12 col-xl-12">
                    <div className="input-group input-group-lg input-group-solid">
                      <select
                        onChange={formik.handleChange}
                        value={formik.values.tipoChiamata}
                        className="form-control form-control-lg form-control-solid"
                        name="tipoChiamata"
                      >
                        <option>Seleziona colonna corrispondente</option>
                        {colonne.map((e, key) => {
                          return (
                            <option key={key} value={key}>
                              {e}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    {formik.touched.tipoChiamata &&
                    formik.errors.tipoChiamata ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.tipoChiamata}
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <label className="col-xl-12 col-lg-12 col-form-label">
                    Data Ricevuta
                  </label>
                  <div className="col-lg-12 col-xl-12">
                    <div className="input-group input-group-lg input-group-solid">
                      <select
                        onChange={formik.handleChange}
                        value={formik.values.dataRicevuta}
                        className="form-control form-control-lg form-control-solid"
                        name="dataRicevuta"
                      >
                        <option>Seleziona colonna corrispondente</option>
                        {colonne.map((e, key) => {
                          return (
                            <option key={key} value={key}>
                              {e}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    {formik.touched.dataRicevuta &&
                    formik.errors.dataRicevuta ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.dataRicevuta}
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <label className="col-xl-12 col-lg-12 col-form-label">
                    Ora Ricevuta
                  </label>
                  <div className="col-lg-12 col-xl-12">
                    <div className="input-group input-group-lg input-group-solid">
                      <select
                        onChange={formik.handleChange}
                        value={formik.values.oraRicevuta}
                        className="form-control form-control-lg form-control-solid"
                        name="oraRicevuta"
                      >
                        <option>Seleziona colonna corrispondente</option>
                        {colonne.map((e, key) => {
                          return (
                            <option key={key} value={key}>
                              {e}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    {formik.touched.oraRicevuta && formik.errors.oraRicevuta ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.oraRicevuta}
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <label className="col-xl-12 col-lg-12 col-form-label">
                    Durata Chiamata
                  </label>
                  <div className="col-lg-12 col-xl-12">
                    <div className="input-group input-group-lg input-group-solid">
                      <select
                        onChange={formik.handleChange}
                        value={formik.values.durata}
                        className="form-control form-control-lg form-control-solid"
                        name="durata"
                      >
                        <option>Seleziona colonna corrispondente</option>
                        {colonne.map((e, key) => {
                          return (
                            <option key={key} value={key}>
                              {e}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    {formik.touched.durata && formik.errors.durata ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.durata}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-6">
                  <label className="col-xl-12 col-lg-12 col-form-label">
                    Numero Dialed
                  </label>
                  <div className="col-lg-12 col-xl-12">
                    <div className="input-group input-group-lg input-group-solid">
                      <select
                        onChange={formik.handleChange}
                        value={formik.values.nrDialed}
                        className="form-control form-control-lg form-control-solid"
                        name="nrDialed"
                      >
                        <option>Seleziona colonna corrispondente</option>
                        {colonne.map((e, key) => {
                          return (
                            <option key={key} value={key}>
                              {e}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    {formik.touched.nrDialed && formik.errors.nrDialed ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.nrDialed}
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <label className="col-xl-12 col-lg-12 col-form-label">
                    Ring Time
                  </label>
                  <div className="col-lg-12 col-xl-12">
                    <div className="input-group input-group-lg input-group-solid">
                      <select
                        onChange={formik.handleChange}
                        value={formik.values.ringTime}
                        className="form-control form-control-lg form-control-solid"
                        name="ringTime"
                      >
                        <option>Seleziona colonna corrispondente</option>
                        {colonne.map((e, key) => {
                          return (
                            <option key={key} value={key}>
                              {e}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    {formik.touched.ringTime && formik.errors.ringTime ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.ringTime}
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <label className="col-xl-12 col-lg-12 col-form-label">
                    Numero Chiamante
                  </label>
                  <div className="col-lg-12 col-xl-12">
                    <div className="input-group input-group-lg input-group-solid">
                      <select
                        onChange={formik.handleChange}
                        value={formik.values.numeroChiamante}
                        className="form-control form-control-lg form-control-solid"
                        name="numeroChiamante"
                      >
                        <option>Seleziona colonna corrispondente</option>
                        {colonne.map((e, key) => {
                          return (
                            <option key={key} value={key}>
                              {e}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    {formik.touched.numeroChiamante &&
                    formik.errors.numeroChiamante ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.numeroChiamante}
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <label className="col-xl-12 col-lg-12 col-form-label">
                    Nome Soggetto
                  </label>
                  <div className="col-lg-12 col-xl-12">
                    <div className="input-group input-group-lg input-group-solid">
                      <select
                        onChange={formik.handleChange}
                        value={formik.values.nomeSoggetto}
                        className="form-control form-control-lg form-control-solid"
                        name="nomeSoggetto"
                      >
                        <option>Seleziona colonna corrispondente</option>
                        {colonne.map((e, key) => {
                          return (
                            <option key={key} value={key}>
                              {e}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    {formik.touched.nomeSoggetto &&
                    formik.errors.nomeSoggetto ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.nomeSoggetto}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </Form>
          </>
        ) : (
          result["message"]
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleCloseImport}>
          {page === "import" ? "Annulla" : "Chiudi"}
        </Button>
        {page === "import" ? (
          <Button
            disabled={load}
            onClick={formik.handleSubmit}
            variant="primary"
          >
            Conferma
          </Button>
        ) : null}
        {page === "result" ? (
          result["code"] === 200 ? (
            <Button
              disabled={load}
              variant="primary"
              onClick={() => {
                setPage("import");
                setColonne([]);
                setDati([]);
              }}
            >
              Nuova importazione
            </Button>
          ) : (
            <Button
              disabled={load}
              variant="primary"
              onClick={() => {
                setPage("import");
                setColonne([]);
                setDati([]);
              }}
            >
              Riprova
            </Button>
          )
        ) : null}
      </Modal.Footer>
    </Modal>
  );
}
