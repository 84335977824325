/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import config from "../../../../config/config";
/*eslint-disable */

export function ListsWidget9({ className }) {
  const { user } = useSelector((state) => state.auth);
  const [events, setEvents] = useState([]);

  const options = {
    headers: {
      Authorization:
        "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
    },
    method: "POST",
    body: JSON.stringify({
      idUser: user.id,
      idOrganization: user.id_organization,
      idDepartment: user.idDepartment,
    }),
  };

  const GetAvvisi = () => {
    fetch(config.apiUrl + "calendar/GetAvvisi.php", options)
      .then((response) => response.json())
      .then((data) => setEvents(data));
  };

  useEffect(() => {
    GetAvvisi();
  }, []);

  return (
    <>
      <div className={`card card-custom  ${className}`}>
        {/* Header */}

        <div className="card-dashboard border-0 pt-5">
          <h3
            style={{ color: "black !important" }}
            className="card-title font-weight-bolder"
          >
            PROSSIMI EVENTI
          </h3>
        </div>
        {/* Body */}
        <div className="card-body pt-3 pb-0">
          <div className="table-responsive">
            <table className="table table-borderless table-vertical-center">
              <thead>
                <tr>
                  <th className="p-0" style={{ width: "200px" }} />
                  <th className="p-0" style={{ minWidth: "100px" }} />
                  <th className="p-0" style={{ minWidth: "125px" }} />
                </tr>
              </thead>
              <tbody>
                {events.length > 0 ? (
                  events.map((e, key) => (
                    <tr key={key}>
                      <td className="pl-0">
                        <a className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
                          {e.title}
                        </a>
                      </td>
                      <td className="text-right">
                        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                          {e.startDate}
                        </span>
                        <span className="text-muted font-weight-bold">
                          {e.startTime}
                        </span>
                      </td>
                      <td className="text-right">
                        <span className="text-muted font-weight-500">
                          {e.description}
                        </span>
                      </td>
                    </tr>
                  ))
                ) : (
                  <p className="text-center text-muted mt-5">
                    NESSUN EVENTO IMMINENTE
                  </p>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
