/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
import { Card, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import config from "../../../../config/config";
/*eslint-disable */

export function MixedWidget1({ className }) {
  const { user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [straordinario, setStraordinario] = useState(0);
  const [permesso, setPermesso] = useState(0);
  const [ferie, setFerie] = useState(0);

  const requestOptions = {
    headers: {
      Authorization:
        "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
    },
    method: "POST",
    body: JSON.stringify({
      idUser: user.id,
    }),
  };

  const GetRiepilogoDashboard = () => {
    setLoading(true);
    fetch(config.apiUrl + "users/GetRiepilogoDashboard.php", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setStraordinario(data["straordinari"]);
        setPermesso(data["permessi"]);
        setFerie(data["ferie"]);
      })
      .then(() => setLoading(false));
  };

  useEffect(() => {
    GetRiepilogoDashboard();
  }, []);

  return (
    <>
      <Card className="my-3">
        <Row className="px-4">
          <div className="col-4 mt-4">
            <div className="card card-custom bg-light-warning card-stretch gutter-b">
              <div className="card-body">
                <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Code/Plus.svg")}
                  ></SVG>
                </span>

                <span className="card-title font-weight-bolder text-warning font-size-h2 mb-0 mt-6 d-block">
                  Straordinari
                </span>
                <span className="font-weight-bold  text-warning font-size-sm">
                  Ore effettuate: {!loading ? straordinario : "/"}
                </span>
              </div>
            </div>
          </div>
          <div className="col-4 mt-4">
            <div className="card card-custom bg-light-danger card-stretch gutter-b">
              <div className="card-body">
                <span className="svg-icon svg-icon-3x svg-icon-danger d-block my-2">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Shopping/Wallet3.svg")}
                  ></SVG>
                </span>
                <span className="card-title font-weight-bolder text-danger font-size-h2 mb-0 mt-6 d-block">
                  Permessi
                </span>
                <span className="font-weight-bold  text-danger font-size-sm">
                  Richiesti: {!loading ? permesso : "/"}
                </span>
              </div>
            </div>
          </div>
          <div className="col-4 mt-4">
            <div className="card card-custom bg-light-info card-stretch gutter-b">
              <div className="card-body">
                <span className="svg-icon svg-icon-3x svg-icon-info d-block my-2">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Clothes/Sun-glasses.svg"
                    )}
                  ></SVG>
                </span>
                <span className="card-title font-weight-bolder text-info font-size-h2 mb-0 mt-6 d-block">
                  Ferie
                </span>
                <span className="font-weight-bold  text-info font-size-sm">
                  Richieste: {!loading ? ferie : "/"}
                </span>
              </div>
            </div>
          </div>
        </Row>
      </Card>
    </>
  );
}
