import config from "../config";

export const UpdateRequest = (payload) => {
  const requestOptions = {
    headers: {
      Authorization: config.keyApi,
    },
    method: "POST",
    body: JSON.stringify({
      accessToken: payload.accessToken,
      value: payload.value,
      idRichiesta: payload.idRichiesta,
      idPresenza: payload.idPresenza,
      dipendenteMail: payload.dipendenteMail,
      dipendente: payload.dipendente,
      type: payload.type,
    }),
  };

  return fetch(config.apiUrl + "calendar/UpdateRequest.php", requestOptions).then((response)=>response.json());
};
